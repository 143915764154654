import React, { Component } from 'react'
import './App.scss'

class Alpha extends Component {

  render() {
    return(
      <div className='slide1'>
        <div className='overlay'></div>
        <h1>Full Service<br />Gaming Lounge</h1>
        <p>A premier cyber lounge, featuring 68 custom spec'd PC's</p>
      </div>
    )
  }
}
export default Alpha
