import React, { Component } from 'react'
import './App.scss'

class Charlie extends Component {

  render() {
    return(
      <div className='slide3'>
        <div className='overlay'></div>
        <h1>Over 120 games<br />to choose from!</h1>
        <p>League of Legends, Overwatch, Battlefield V, World of Warcraft, Rainbow six siege, etc</p>
      </div>
    )
  }
}
export default Charlie
